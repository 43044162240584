@charset "utf-8";
/* CSS Document */

/* タブ切り替え全体のスタイル */
.tabs {
  width: 730px;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 40px;
  background-color: #fff;
}

/* タブのスタイル */
.tab_item {
  display: block;
  float: left;
  width: 85px/*calc(100%/8)*/;
  height: 30px;
  margin:0 4px 0 0;
  background-color: #fff;
  border:1px solid #444;
  border-bottom:1px solid #fff;
  color: #444;
  font-size: 12px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  transition: all 0.2s ease;
  border-radius: 10px 10px 0 0;
}
.tab_item:hover {
  opacity: 0.75;
}

/* ラジオボタンを全て消す */
input[name="tab_item"] {
  display: none;
}

/* タブ切り替えの中身のスタイル */
.tab_content {
  display: none;
  padding: 10px;
  clear: both;
  overflow: hidden;
  border:1px solid #444;  
}

/* 選択されているタブのコンテンツのみを表示 */
#gift:checked ~ #gift_content,
#beer:checked ~ #beer_content,
#sake:checked ~ #sake_content,
#spirits:checked ~ #spirits_content,
#wine:checked ~ #wine_content,
#whiskey:checked ~ #whiskey_content,
#cocktail:checked ~ #cocktail_content,
#other:checked ~ #other_content {
  display: block;
}

/* 選択されているタブのスタイルを変える */
.tabs input:checked + .tab_item {
  background-color: #444;
  color: #fff;
  border-bottom:1px solid #444;
}

.tab_content iframe {
  width: 100%;
  height: 240px;
}


@media screen and (max-width: 480px) {
.tabs {
  width: 360px;
  margin: 0 auto;
  margin-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.tab_item {
  display: block;
  float: left;
  width: 84px/*calc(100%/8)*/;
  height: 30px;
  margin:0 4px 0 0;
  background-color: #fff;
  border:1px solid #444;
  border-bottom:1px solid #fff;
  color: #444;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  font-weight: bold;
  transition: all 0.2s ease;
  border-radius: 10px 10px 0 0;
}
.tab_content {
  display: none;
  padding: 10px;
  clear: both;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  border:1px solid #444;  
}

.tab_content iframe {
  width: 730px;
}
}